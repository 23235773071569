.wrapper-all-content {
  background-color: #fff;
  position: relative;
  width: 100%;
}

.welcome-section {
  padding: 50px 0;
  @include respond-to('medium') {
    padding: 40px 0;
  }
  @include respond-to('small') {
    padding: 0px 0 10px;
  }
  &__title {
    margin: 0 0 20px;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: $accent-blue;
    @include respond-to('extra-small') {
      margin: 0 0 10px;
      font-size: 26px;
    }
  }
  p {
    max-width: 838px;
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
    @include respond-to('extra-small') {
      text-align: justify;
    }
  }
}

.homepage__buttons {
  padding: 0px 0 50px;
  @include respond-to('small') {
    padding: 0px 0 30px;
  }
  @include respond-to('extra-small') {
    padding: 0px 0 20px;
  }
}

.big-title {
  margin: 0;
  font-size: 46px;
  text-align: center;
  @include respond-to('small') {
    font-size: 36px;
  }
  @include respond-to('extra-small') {
    font-size: 26px;
  }
  &_blue {
    margin-top: 50px;
    font-weight: 400;
    color: $accent-blue;
    @include respond-to('small') {
      margin-top: 0px;
    }
  }
  &_green {
    margin-bottom: 150px;
    font-weight: 700;
    color: $accent-green;
    @include respond-to('small') {
      margin-bottom: 70px;
    }
    @include respond-to('extra-small') {
      margin-bottom: 30px;
    }
  }
}
