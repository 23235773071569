.wrapper-banners {
  position: relative;
  width: 100%;
  height: 900px;
  overflow: hidden;
  @include respond-to('huge') {
    height: 800px;
  }
  @include respond-to('larger') {
    height: 700px;
  }
  @include respond-to('large') {
    height: 600px;
  }
  @include respond-to('medium') {
    height: 500px;
  }
  @include respond-to('small') {
    height: 400px;
  }
  @include respond-to('extra-small') {
    height: 300px;
  }
}

.wrap-banner-scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include respond-to('medium') {
    position: static;
  }
}

.banner-item {
  width: 100%;
  min-height: 900px;
  position: relative;
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  @include respond-to('huge') {
    min-height: 800px;
  }
  @include respond-to('larger') {
    min-height: 700px;
  }
  @include respond-to('large') {
    min-height: 600px;
  }
  @include respond-to('medium') {
    min-height: 500px;
  }
  @include respond-to('small') {
    min-height: 400px;
  }
  @include respond-to('extra-small') {
    min-height: 300px;
  }
}

.slide-text {
  position: absolute;
  #{$right}: 18%;
  bottom: 65%;
  @include respond-to('larger') {
    bottom: 50%;
  }
  @include respond-to('small') {
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    #{$right}: 80px;
  }
  @include respond-to('extra-small') {
    #{$right}: 15px;
		top: 47%;
		max-width: 460px;
		width: 90%;
  }
  & h2 {
    max-width: 615px;
    max-height: 180px;
    margin: 0;
    font-size: 37px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #3e6a00;
    text-indent: -60px;
    @include respond-to('extra-small') {
      font-size: 30px;
      font-weight: 600;
      text-indent: 0px;
      overflow: hidden;
    }
  }
}

.slide-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-image: url(../images/slide-shadow.png);
  height: 536px;
  width: 100%;
  @include respond-to('medium') {
    height: 476px;
  }
  @include respond-to('small') {
    height: 420px;
  }
}

.slide-buttons-block-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.slide-buttons-block {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  @include respond-to('small') {
    position: static;
    margin-top: 30px;
  }
	@include respond-to('extra-small') {
    margin-top: 20px;
  }
}

.slide-button {
  display: block;
  @include respond-to('small') {
    margin-bottom: 30px;
		//display: block;
		//margin: 0 auto;
  }
  &__img {
    max-width: 100%;
    transform: rotateY(0deg);
    transition: transform .5s ease;
		@include respond-to('mobile-m') {
    max-width: none;
  }
  }
  &__title {
    margin-top: 20px;
    margin-bottom: 0;
    color: #000;
    font-size: $font-size-large;
    font-weight: 600;
		@include respond-to('extra-small') {
    margin-top: 10px;
  }
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
  &:hover {
    & .slide-button__img {
      transform: rotateY(-180deg);
    }
  }
}
