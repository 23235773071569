footer {
  width: 100%;
  position: relative;
  background-color: #161616;
  z-index: 1;
  padding: 50px 0 50px;
  @include respond-to('large') {
    padding: 20px 0;
  }
}

.hr-footer-bottom {
  border: 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #313131;
  margin-top: 60px;
  margin-bottom: 15px;
  @include respond-to('medium') {
    margin-top: 15px;
  }
}

.credit-info-txt {
  display: inline-block;
  @include respond-to('medium') {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  @include respond-to('small') {
    margin-right: 0;
  }
  &>p {
    font-size: 16px;
    color: #000;
    font-weight: 300;
    margin: 0;
    @include respond-to('extra-small') {
      font-size: 14px;
    }
  }
}

.wrap-social {
  position: relative;
  display: inline-block;
  @include respond-to('medium') {
    display: block;
    text-align: center;
    margin: 0;
  }
  & ul {
    padding: 0;
    margin: 0;
    list-style: none;
    @include respond-to('medium') {
      display: inline-block;
    }
    & li {
      float: $right;
      color: #fff;
      line-height: 40px;
      font-size: 14px;
      font-weight: 400;
      @include respond-to('small') {
        font-weight: 300;
        font-size: 11px;
        line-height: 25px;
      }
      &:first-child {
        margin-#{$left}: 6px;
        @include respond-to('small') {
          margin-#{$left}: 0px;
        }
        &::after {
          content: '';
        }
      }
      &:last-of-type {
        &::after {
          content: '';
        }
        & a {
          color: $accent;
        }
      }
      &::after {
        content: "|";
        padding: 0 6px;
        @include respond-to('small') {
          padding: 0 3px;
        }
      }
      & a {
        & img {
          @include respond-to('small') {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.site-footer__credit-holder {
  float: #{$left};
  @include respond-to('medium') {
    float: none;
  }
}

.footer-nav {
  margin-top: 10px;
	text-align: center;
  @include respond-to('medium') {
    display: none;
  }
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
		display: inline-block;
    & li {
      float: #{$right};
      &::after {
        content: "|";
        color: #fff;
      }
      &:last-of-type {
        &::after {
          content: "";
        }
      }
      & a {
        padding: 0 3px;
        color: $accent;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}