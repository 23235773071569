.ftr-wrap-links {
  //max-width: 170px;
  @include respond-to('small') {
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
	@include respond-to('mobile-l') {
    margin-bottom: 15px;
  }
  &>ul {
    padding: 0;
    margin: 0;
    list-style: none;
    &>li {
      margin-bottom: 12px;
      @include respond-to('small') {
        margin-bottom: 5px;
				text-align: center;
      }
      &>a {
        font-size: 14px;
        font-weight: 400;
        color: #7c7c7c;
        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
}