.main-nav {
    ul {
        list-style: none;

        li {
            display: inline-block;

            a {
                color: #000;
            }

            &::after {
                content: " | ";
                color: #c1c1c1;
                margin: 0 10px;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }
}
