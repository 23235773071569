.wrap-content {
  padding: 20px 0 0;
}

.title {
  font-size: 36px;
  font-weight: 800;
  line-height: 1;
  color: $accent;
  margin: 30px 0 0;
	@include respond-to('extra-small') {
		font-size: 30px;
		margin: 0;
	}
}

.hr-content {
  border-top: 1px solid #e6e6e6;
  margin: 30px 0;
	@include respond-to('extra-small') {
		margin: 15px 0 ;
	}
}

.content-text {
  h2,
  h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
    color: $accent;
    @include respond-to('large') {
      font-size: 26px;
    }
    @include respond-to('extra-small') {
      font-size: 20px;
    }
  }
  p {
    font-size: 20px;
    font-weight: 400;
    color: #415667;
    line-height: 1.2;
    @include respond-to('extra-small') {
      font-size: 16px;
      text-align: justify;
    }
  }
  ul, ol {
    padding: 0;
    list-style-position: inside;
		margin-bottom: 30px;
		@include respond-to('extra-small') {
      margin-bottom: 20px;
    }
    & li {
			margin-bottom: 15px;
      color: #415667;
      font-size: 20px;
      font-weight: 400;
      color: #415667;
      line-height: 1.2;
      @include respond-to('extra-small') {
        font-size: 16px;
      }
    }
  }
  iframe {
    @include respond-to('small') {
      text-align: center !important;
      margin: 15px auto !important;
      width: 100% !important;
      height: auto !important;
      float: none !important;
      display: block !important;
    }
  }
  img {
    @include respond-to('small') {
      float: none !important;
      text-align: center !important;
      width: auto !important;
      height: auto !important;
      margin: 15px auto !important;
    }
  }
}