.faq {
  margin: 0;
  padding: 0;
  &>li {
    list-style: none;
    margin-bottom: 35px;
		@include respond-to('extra-small') {
			margin-bottom: 15px;
		}
    &:first-child {
      margin-top: 0;
    }
  }
  &__question {
		//position: relative;
		display: inline-block;
    font-size: 24px;
    font-weight: 400;
    color: #415667;
    line-height: 1.2;
    cursor: pointer;
		padding-#{$left}: 20px;
    @include respond-to('extra-small') {
      font-size: 18px;
      text-align: justify;
    }
    & span {
			display: inline-block;
			//position: absolute;
			#{$left}: 0;
			top: 0;
      margin-#{$right}: 12px;
			transform: rotate(0deg);
			transition: transform .4s linear;
    }
  }
  &__answer {
    display: none;
    padding-#{$right}: 25px;
    & li, p {
      list-style: disc;
      margin-bottom: 15px;
      color: #415667;
      font-size: 20px;
      font-weight: 400;
      color: #415667;
      line-height: 1.2;
      @include respond-to('extra-small') {
        font-size: 16px;
      }
    }
  }
}

.open-arrow {
  & span {
    color: $accent;
    transform: if-rtl((rotate(90deg)), (rotate(-90deg)));
		transition: transform .4s linear;
  }
}
