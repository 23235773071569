.main-header {
  width: 100%;
  z-index: 99;
  position: fixed;
  top: 0;
  background-image: if-rtl(url(../images/header-back.png), url(../images/header-back-ltr.png));
  background-repeat: no-repeat;
  background-position: 50%;
  @include respond-to('larger') {
    background-position: $left -300px center;
  }
  @include respond-to('medium') {
    background-image: none;
  }
}

.header-desktop {
  position: relative;
  height: 145px;
  padding-top: 1px;
  @include respond-to('medium') {
    display: none;
  }
}

// .logo {
//   position: absolute;
//   top: 0;
//   //#{$left}: 18%;
//   margin-top: 10px;
// }
.main-navi {
  display: block;
  position: relative;
  width: 1070px;
  margin-top: 47px; //margin-#{$right}: 17%;
  //padding-right: 28px;
  //border-top: 1px solid #e7e7e7;
  @include respond-to('larger') {
    width: 84.5%;
  }
  &>ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0; // transition: margin .3s linear; // @include respond-to('huge') {
    //   padding: side-values(0 0 0 220px);
    // }
    // @include respond-to('larger') {
    //   margin: side-values(38px 5px 0 0);
    //   padding: side-values(0 0 0 200px);
    // }
    // @include respond-to('large') {
    //   padding: side-values(0 0 0 170px);
    // }
    &>li {
      float: $right; //transition: all .3s linear;
      padding-bottom: 18px;
      &:hover {
        & .drop-menu {
          display: block;
          //visibility: visible;
          //opacity: 1;
        }
      }
      &.facebook {
        & a {
          color: $clr-facebook;
          padding-#{$left}: 0;
          &:hover,
          &:active,
          &:focus {
            color: $clr-facebook;
          }
        }
      }
      &>a {
        font-size: $font-size-medium;
        font-weight: 400;
        color: #000;
        padding-#{$left}: 13px;
        display: block;
				//ransition: text-shadow .1s;
        @include respond-to('large') {
          padding-#{$left}: 8px;
          font-size: 18px;
        }
        &:hover,
        &:active,
        &:focus {
          color: $accent;
          text-decoration: none;
					text-shadow: 0.5px 0.5px 0px $accent,  0.5px -0.5px 0px $accent,  -0.5px -0.5px 0px $accent, -0.5px 0.5px 0px $accent;
        }
      }
    }
  }
}

// .nav-search {
//   position: absolute;
//   top: -38px;
//   & form {
//     position: relative;
//   }
//   & input {
//     width: 120px;
//     height: 28px;
//     border: 1px solid $accent;
//     border-radius: 4px;
//     padding-#{$right}: 41px;
//     font-size: 17px;
//     line-height: 28px;
//     transition: all .1s linear;
//     &::placeholder {
//       color: #979797;
//       font-size: 17px;
//       font-weight: 700;
//     }
//   }
//   & .header-button {
//     position: absolute;
//     #{$right}: 0px;
//     top: 0px;
//     height: 100%;
//     border: 1px solid $accent;
//     border-left: none;
//     border-top-right-radius: 4px;
//     border-top-left-radius: 0px;
//     border-bottom-right-radius: 4px;
//     border-bottom-left-radius: 0px;
//     padding: 0;
//     height: 28px;
//     width: 30px;
//     background-image: $search-icon;
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
//     transition: all .1s linear;
//   }
// }
// .home {
//   margin-#{$right}: -40px;
// }
.header-caret {
  margin-#{$left}: 4px;
  color: $accent;
}

.facebook {
  position: absolute;
  #{$left}: 0; //top: -38px;
  @include respond-to('larger') {
    position: static;
    margin-#{$right}: 10px;
  }
  @include respond-to('1100') {
    display: none;
  }
}

.phone-number {
  #{$left}: 22px;
  position: absolute; //top: -38px;
  @include respond-to('larger') {
    position: static;
  }
  @include respond-to('1100') {
    display: none;
  }
}

.phone {
  display: inline-block;
  height: 22px;
  width: 17px;
  margin-#{$left}: 10px;
  background-image: $phone-icon;
  line-height: 28px;
  vertical-align: sub;
}

.number {
  color: $accent;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: 28px;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $accent;
  }
}

.drop-menu {
  //visibility: hidden;
  display: none;
  position: absolute;
  min-width: 140px;
  padding: 0;
  list-style: none;
  top: 40px;
  //opacity: 0;
  transition: visibility .5s ease, opacity .5s ease;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
  & li {
    position: relative;
    display: block; //width: 160px;
    //height: 177px;
    padding: 5px 10px;
    background-color: #00acee;
    border-top: 1px solid #e5e5e5;
    &:hover {
      border-top: 1px solid #fff;
    }
    & a {
      line-height: 26px;
      color: #fff;
      font-weight: 400;
      text-decoration: none;
      &:hover,
      &:active,
      &:focus {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.header-mobile {
  display: none;
  height: 70px;
  background-color: rgba($color: #fff, $alpha: .95);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
  @include respond-to('medium') {
    display: block;
  }
  &__logo {
    margin-#{$left}: 0px;
    margin-top: 3px;
    float: $left;
    &>img {
      width: 80%;
      @include respond-to('extra-small') {
        // width: 180px;
      }
    }
  }
  &__tel {
    display: inline-block;
    margin-top: 18px;
    svg {
      color: $accent;
      font-size: 32px;
    }
  }
}

.mobile-menu-btn {
  margin: 19px 15px 0 15px;
  & .hamburger-inner {
    background-color: $accent;
    &::before,
    &::after {
      background-color: $accent;
    }
  }
}

.main-header.scrolling {
  background-image: if-rtl(url(../images/header-back-mobile.png), url(../images/header-back-mobile-ltr.png));
  background-repeat: no-repeat;
  @include respond-to('larger') {
    background-position: $left -330px center;
  }
  @include respond-to('medium') {
    background-image: none;
  }
  .header-desktop {
    height: 95px;
  }
  .main-navi {
    border-top: none;
    margin-top: 14px;
    &>ul {
      & li {
        & a {
          font-size: 18px;
          font-weight: 400;
        }
      } // & .nav-search {
      //   //top: -31px;
      //   & input {
      //     width: 80px;
      //     height: 20px;
      //     padding-#{$right}: 34px;
      //     line-height: 20px;
      //     font-size: 15px;
      //     &::placeholder {
      //       font-size: 15px;
      //     }
      //   }
      //   & .header-button {
      //     top: 3px;
      //     height: 20px;
      //     width: 20px;
      //   }
      // }
      //& .home {
      //margin-#{$right}: 90px;
      //}
      & .facebook {
        //top: -32px;
        & a {
          font-size: 20px;
        }
      } //& .phone-number {
      //top: -32px;
      //}
    }
  }
  & .logo {
    #{$left}: 406px;
    margin-top: 3px; // @include respond-to('huge') {
    //   max-width: 200px;
    // }
    // @include respond-to('large') {
    //   max-width: 150px;
    // }
  }
}
