.banner-top-content {
  position: relative;
  width: 100%;
  min-height: 400px;
  @include respond-to('small') {
    min-height: auto;
  }
  & img {
    text-align: center;
    vertical-align: middle;
    max-width: 100%;
  }
}

.banner-top-content__image {
  background-position: center center;
  background-size: cover;
  height: 400px;
  @include respond-to('small') {
    height: auto;
  }
}
