.homepage-slider-slide {
    height: 700px;
    position: relative;
    background-size: cover;
    background-position: center;
}

.homepage-slider-slide-text{
    margin-top:250px;
    max-width: 300px;
    background-color:rgba(255,255,255,0.8);
    padding:10px;
    h2{
        margin-top:0;
    }
    p{
        font-weight: 600;
    }
    a{
        font-weight: 600;
    }
}
