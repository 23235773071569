.ftr-form {
  padding-#{$right}: 83px;
  @include respond-to('larger') {
    padding-#{$right}: 0px;
  }
  @include respond-to('small') {
    text-align: center;
    margin-bottom: 20px;
  }
}

.ftr-form-title {
  margin-bottom: 15px;
  &>p {
    font-family: 'Opes Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 1;
    margin: 0;
  }
  @include respond-to('1100') {
    text-align: center;
  }
}

.input-wrap {
  float: $right;
  @include respond-to('1100') {
    float: none;
    margin: 0 auto;
    text-align: center;
  }
}

.textarea-btn-wrap {
  float: $left;
  @include respond-to('larger') {
    float: #{$right};
    margin-#{$right}: 10px;
  }
  @include respond-to('1100') {
    float: none;
    margin-#{$right}: 0px;
    text-align: center;
  }
}

.form-group {
  margin-bottom: 6px;
}

.newslatter-input {
  width: 195px;
  height: 40px;
  background-color: #fff;
  position: relative;
  color: #000;
  font-size: 20px;
  font-weight: 400;
  padding: 10px;
  border-radius: 0;
  box-shadow: none;
  @include respond-to('1100') {
    margin: 0 auto;
  }
  @include respond-to('small') {
    margin-left: auto;
    margin-right: auto;
  }
  @include respond-to('extra-small') {
    height: 30px;
    font-size: 18px;
  }
  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0;
    }
  }
  &::placeholder {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    transition: opacity 0.4s ease;
    @include respond-to('extra-small') {
      font-size: 18px;
    }
  }
}

textarea.newslatter-input {
  padding-top: 4px;
  resize: none;
  height: 133px;
  @include respond-to('extra-small') {
    height: 70px;
  }
}

.newslatter-btn {
  float: $left;
  display: block;
  margin-top: 8px;
  padding: 6px 14px;
  border: none;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  background-color: $accent;
  position: relative;
  @include respond-to('1100') {
		float: none;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond-to('extra-small') {
    padding: 1px 14px;
  }
  &:focus {
    outline: none;
  }
  &:active {
    @include btn-effect-active;
  }
  &>svg {
    font-size: 18px;
    margin-#{$right}: 5px;
    transition: all 0.4s;
  }
  &:hover {
    &>svg {
      transform: if-rtl((translateX(-4px)),(translateX(4px)));
    }
  }
}

.help-block {
  margin: 0;
  & ul {
    margin: 0;
  }
}