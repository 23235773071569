.homepage-box {
  position: relative;
  display: block;
  text-decoration: none;
  color: #000;
  @include respond-to('small') {
    max-width: 400px;
    margin: 0 auto 25px;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: #000;
    & .homepage-box__title {
      color: $accent-blue;
    }
  }
  &__image {
    overflow: hidden;
    img {
      transition: transform 200ms;
    }
  }
  &__title {
    margin: 25px 0;
    font-size: 20px;
    font-weight: 600;
    color: $accent-blue;
    @include respond-to('mobile-l') {
      margin: 15px 0;
    }
  }
  &__description {
    text-align: justify;
    font-weight: 400;
    font-size: 15px;
  }
  &__link {
    margin-top: 45px;
		@include respond-to('mobile-l') {
      margin-top: 25px;
    }
    & p {
      margin-bottom: 0;
      font-size: 15px;
      font-weight: 300;
    }
  }
  @include on-event(false) {
    img {
      transform: scale(1.1);
    }
  }
}