.contact-us-form,
.contact-info,
.contact-map {
  padding: 35px 20px 20px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background-color: #fcfcfc;
  @include respond-to('extra-small') {
    padding: 15px;
  }
}

.contact-map {
  margin-top: 30px;
  padding: 20px;
  @include respond-to('extra-small') {
    margin-top: 15px;
    padding: 15px;
  }
}

.contact-us-form {
  @include respond-to('medium') {
    margin-bottom: 30px;
  }
  &__title {
    margin-bottom: 50px;
    font-size: 25px;
    font-weight: 700;
    color: $accent;
    @include respond-to('extra-small') {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
  &__subtitle {
    font-size: 20px;
    font-weight: 600;
    color: $accent-blue;
    margin-bottom: 13px;
    @include respond-to('extra-small') {
      margin-bottom: 10px;
      font-size: 18px;
    }
  }
}

.contact-info {
  & .col-xs-2 {
    max-width: 42px;
    padding-#{$left}: 0;
  }
  padding-#{$right}: 35px;
  @include respond-to('medium') {
    padding-#{$right}: 20px;
  }
  @include respond-to('extra-small') {
    padding: 15px;
  }
  &__title {
    font-size: 25px;
    font-weight: 700;
    color: $accent;
    @include respond-to('extra-small') {
      font-size: 20px;
    }
  }
  &__icon {
    padding-#{$right}: 60px;
    margin-top: 60px;
    @include respond-to('medium') {
      padding-#{$right}: 0px;
      margin-top: 30px;
    }
    &:nth-child(2) {
      margin-top: 90px;
      @include respond-to('medium') {
        margin-top: 30px;
      }
    }
  }
}

.icon {
  float: $right;
  height: 42px;
  width: 42px;
  background-image: url(../images/contact-icons.png);
  background-repeat: no-repeat;
  background-size: cover;
  &_location {
    background-position: 0 0;
  }
  &_phone-us {
    background-position: 0 -165px;
  }
  &_fax {
    background-position: 0 -262px;
  }
  &_mail {
    background-position: 0 -352px;
  }
}

.location-desc,
.phone-desc,
.fax-desc,
.mailto-desc {
  float: $right;
  line-height: 42px;
  margin-#{$right}: 22px;
  font-size: 18px;
  font-weight: 600;
  color: #777777;
  @include respond-to('extra-small') {
    margin-#{$right}: 12px;
    font-size: 18px;
    font-weight: 500;
  }
}

.mailto-desc {
  color: $accent;
  &:hover {
    color: $accent;
    text-decoration: none;
  }
}

.contact-form {
  & .form-group {
    margin-bottom: 10px;
    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }
  & .newslatter-input {
    max-width: 100%;
    width: 100%;
    border: 1px solid $accent;
    border-radius: 4px;
    color: #777777;
    padding: 10px 5px;
    box-shadow: none;
    &::placeholder {
      color: #777777;
      font-size: 18px;
      font-weight: 400;
    }
  }
  & textarea.newslatter-input {
    height: 270px;
    @include respond-to('extra-small') {
      height: 100px;
    }
  }
  & .newslatter-btn {
    float: $right;
    margin-top: 0;
    padding: 6px 16px;
    border-radius: 4px;
    font-weight: 600;
    @include respond-to('extra-small') {
      float: none;
      margin: 0 auto;
    }
  }
}

.mapbox {
  min-height: 280px;
}